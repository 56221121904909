body{
    font-family: 'Bree Serif', sans-serif;
}
#root{
    width: 100vw;
    padding: 0;
    background-color: azure;
}
.container{
    display: flex;
}
.champ-list{
    display: flex;
    flex-flow: row wrap;
    transition: transform .2s;
    height: 100vh;
    overflow-y: scroll;
}
.champ{
    height: 60px;
}
.champ img{
    width: 60px;
}
.champ-name{
    display: none;
}
.champ img:hover{
    transform: scale(2);
}
.champ-detail,.champ-detail-loading{
    position: relative;
    height: fit-content;
    min-width: 310px;
}
.champ-detail-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.champ-detail h3{
    position: absolute;
    font-size: 1.5em;
    width: 100%;
    padding: 20px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.705), rgba(255, 255, 255, 0) 80%);
    top: 0;
    
}
.champ-detail span{
    font-size: 14px;
}
.spell-list{
    position: absolute;
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    bottom: 0;
    margin: 20px;
}

.spell-list img{
    border: 1px solid white ;
}
.spell-list img.active{
    outline: 3px solid gold;
}
.spell-list img:not(img.active){
    opacity: 0.7;
}
.spell-detail{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.664);
    width: 70vw;
    left: 100px;
    bottom: 0;
    padding: 10px;
}
.spell-detail div{
    display: flex;
}
.spell-detail video{
    width: 100%;
    max-width: 400px;
}

.pasif{
    position: relative;
}
.pasif p{
    position: absolute;
    color: black;
    font-size: 10px;
    padding: 5px;
    bottom: 0;
    width: 100%;
    letter-spacing: 0.1rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.705), rgba(255, 255, 255, 0) 80%); ;
}
.stats{
    background-color: rgba(255, 255, 255, 0.336);
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px;
    word-spacing: 2em;
}
.skin-list{
    width: 305px;
    display: flex;
    overflow: scroll;
}
.skin{
    display: flex;
    flex-direction: column;
    margin: 10px;
    
}

.skin-list .active{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.603);
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0; 
}
.skin-list .active img{
    margin:auto;
}

.skin-list .skin img{
    width: 100%;
    min-width: 150px;
}

@media screen and (max-width:450px){
    .container{
        display: flex;
        flex-direction: column;
        
    }
    .champ-detail img:first-child{
        width: 95%;
    }
    .champ-list{
        width: 3000px;
        height: fit-content;
    }
    .list{
        width: 100vw;
        overflow: scroll;
    }
    .stats{
        right: 30px;
    }
    .skin-list{
        width: 100%;
    }
    .spell-detail video{
        width: 100%;
    }
}